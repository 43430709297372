body{
    margin: 0;
}
.LandingPage {
    display: flex;
    min-height: 100vh; 
    font-family: 'Lato', sans-serif;
    background-color: white;
    flex-direction: column;
}
  
.LandingPage-Header {
    font-size: 2rem;
    text-align: center;
    color: black;
    font-weight: 900;
    font-style: italic;
    margin-right: 3%;
    margin-left: 3%;
    color: black;
}
.LandingPage-Logo-Div {
    padding: 20px; 
    background-color: black; 
    text-align: center;
    border-bottom: 10px solid #c5b358;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    margin-bottom: 20px;
    left: 0;
    top: 0;
}
.LandingPage-Logo {
    max-height: 15vh;
    max-width: 40vw;
}
.LandingPage-NFT-Div {
    text-align: center;
    justify-content: center;
    margin-top: 20px;
}
.LandingPage-NFT {
    width: 100%;
}
hr.style6 {
	background-color: #fff;
	border-top: 2px dotted #8c8b8b;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}
.Collection-Btn{
    background-color: black;
    color: #c5b358;
    font-family: 'Lato', sans-serif;
    width: 80%;
    padding: 15px;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 500;
    border-radius: .3rem;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.Claim-Btn{
    background-color: #c5b358;
    color: white;
    font-family: 'Lato', sans-serif;
    display: inline;
    width: 90%;
    padding: 15px;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 900;
    border-radius: .3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.Claim-Address{
    font-weight: 300;
    color: gray;
    font-size: .9rem;
}
.Claim-login{
    background-color: #c5b358;
    color: white;
    font-family: 'Lato', sans-serif;
    display: inline;
    width: 48%;
    padding: 5px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: .3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.Claim-logout{
    background-color: gray;
    color: white;
    font-family: 'Lato', sans-serif;
    display: inline;
    width: 48%;
    margin-left: 26%;
    padding: 5px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: .3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.Claim-Name{
    font-weight: 600;
    margin-bottom: 28px;
    font-size: 1.5rem;
}

@media only screen and (min-width: 1024px) {
    .LandingPage-NFT {
        width: 40%;        
    }
    .LandingPage-Logo {
        max-height: 8vh;
        max-width: 40vw;
    }
    .DesktopContainer{
        width: 50%;
        padding-left: 25%;
        padding-right: 25%;
    }
}

/* FAQs */
.FAQ{
    padding: 5%;
    padding-top: 3%;
}
.FAQ-Header{
    color: black;
    font-weight: 900;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 40px;
}
.FAQ-q{
    color: #c5b358;
    font-weight: 900;
    font-size: 1.75rem;

    margin-bottom: 16px;
    text-shadow: 1px 1px gray;
}
.FAQ-a{
    color: black;
    font-weight: 300;
    font-size: 1rem;
}
.FAQ-hr{
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 2px;
    
    background-color: rgb(200,200,200);
}
@media only screen and (min-width: 1024px) {
    .FAQ{
        padding: 2%;
        padding-top: 3%;
        padding-bottom: 3%;
    }
}
